<template>
  <div>
    <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('/images/company/aboutus.jpg');">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">Event</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ event.title }}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->
    <section class="">
      <div class="container">
        <div class="container mt-5">
          <div class="row ">
            <div class="col-md-12 bg-custom mt-5">
              <div class="card-group evet-card text-light mb-2">
                <div class="mb-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-3 col-md-12 col-sm-12">
                        <div class="display-4 ">
                          <span class="badgee">{{ moment(jsonDecode(event.data).event_date).format('D MMMM') }}</span>
                        </div>
                        <ul class="list-group my-0 pl-1">
                          <li class="list-inline-item">
                            <i data-feather="clock" class="fea icon-sm icons"></i>{{ moment(jsonDecode(event.data).start_time,'hh:mm:ss').format('hh:mm a')+' - '+moment(jsonDecode(event.data).end_time,'hh:mm:ss').format('hh:mm a') }}
                          </li>
                          <li class="list-inline-item"><i data-feather="calendar" class="fea icon-sm icons"></i>
                            {{ jsonDecode(event.data).event_date }}
                          </li>
                          <li class="list-inline-item"><i data-feather="map-pin" class="fea icon-sm icons"></i>
                            {{ jsonDecode(event.data).location }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-9 col-md-12 col-sm-12">
                        <div class="d-flex flex-column">
                          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" data-interval="1200">
                            <div class="carousel-inner">
                              <div v-for="(img,key) in jsonDecode(event.data).images" :class="key==0?'carousel-item active':'carousel-item'" :key="key">
                                <img :src="imgShow(img)" class="d-block w-100" alt="...">
                              </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                              <span class="carousel-control-next-icon" aria-hidden="true"></span>
                              <span class="visually-hidden">Next</span>
                            </button>
                          </div>
                          <h3 class="card-title text-uppercase mt-4" style="color: rgba(112, 131, 53)">
                            <strong>{{ event.title }}</strong>
                          </h3>
                          <div class="card-text text-custom2" v-html="event.description"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div><!--end container-->
    </section>

  </div>

</template>

<script>

import feather from 'feather-icons'
import apiCall from "../../../core/apiCall";
import moment from "moment";
export default {
  name: 'SingleEvent',
  props:{
    id:{
      required:true,
    }
  },
  data(){
    return{
      event:{},
      moment,
    }
  },
  created(){
    this.getSingleEvent();
  },
  methods:{
    jsonDecode(data){
      let parse_data= {};
      try{
        parse_data =JSON.parse(data);
      }catch (e){parse_data={};}
      return parse_data;
    },
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
    async getSingleEvent(){
        this.$store.commit('LOADER_STATUS_CHANGE',true);
          await apiCall.get(`/get/public/single/event${this.id}`)
              .then((response) => {
                this.event =response.data;
                this.$store.commit('LOADER_STATUS_CHANGE',false);
              }).catch(()=>{
                this.event={};
                this.$store.commit('LOADER_STATUS_CHANGE',false);
              })
      }
  },
  mounted() {
    feather.replace();
  }
}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center {
  text-align: center;
  margin: auto;
  width: 60%;
  padding: 10px;
}

.card-text {
  text-align: justify;
  text-justify: inter-word;
}

.wrapper {
  margin: 15px auto;
  max-width: 1100px;
}

.container-calendar {
  background: #ffffff;
  padding: 15px;
  /*     max-width: 475px; */
  margin: 0 auto;
  overflow: auto;
}

.button-container-calendar button {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #247444;
  color: #fff;
  border: 1px solid #247444;
  border-radius: 4px;
  padding: 5px 10px;
}

.table-calendar {
  border-collapse: collapse;
  width: 100%;
}

.table-calendar td, .table-calendar th {
  padding: 5px;
  border: 1px solid #e2e2e2;
  text-align: center;
  vertical-align: top;
}

.date-picker.selected {
  font-weight: bold;
  /*   outline: 1px dashed #00BCD4; */
}

.date-picker.selected span {
  display: block;
  /*   border-bottom: 2px solid currentColor; */
}

/* sunday */
.date-picker:nth-child(1) {
  color: red;
}

/* friday */
.date-picker:nth-child(6) {
  color: green;
}

#monthAndYear {
  text-align: center;
  margin-top: 0;
}

.button-container-calendar {
  position: relative;
  margin-bottom: 1em;
  overflow: hidden;
  clear: both;
}

#previous {
  float: left;
}

#next {
  float: right;
}

#calendar-body .date-group {
  display: none;
}

#calendar-body .date-group.currentweek {
  display: block;
}

#calendar-body .date-group.currentweek {
  font-size: 70px;
  line-height: 1;
  font-weight: bold;
  color: #247444;
  display: block;
}

#calendar-body .date-group.currentweek .date-picker.selected {
  background: #247444;
  /* color: #fff!important; */
  border-color: transparent;
  border-radius: 5px;
}

#thead-month .thead-head > div,
#calendar-body .date-group > div {
  display: inline-block;
  padding: 0 15px;
  margin: 0 auto;
  width: 14%;
  min-width: 60px;
  text-align: center;
}

.footer-container-calendar {
  margin-bottom: 1em;
  /* border-bottom: 1px solid #dadada; */
  padding: 10px 0;
}

.footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  /* background: #ffffff; */
  color: #585858;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}

.evet-card > .card .list-inline-item {
  color: #247444;
  font-size: 14px;
}

.list-inline-item {
  color: #247444;
  font-size: 14px;
}

.evet-card > .card .badgee {
  background: #247444;

}

.badgee {
  background: #247444;
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}

.evet-card > .card {
  background: #FFFFF0;
}
</style>
